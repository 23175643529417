
import React, { useEffect, useState } from "react";
import { useJobs } from "../../hooks/useJobs/useJobs";

const HeatmapChart = ({ selectedJobId, generalSelectedProgram, selectedProgram }) => {
  const { getJobs, getJobById } = useJobs();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(selectedJobId);
  const [heatmapData, setHeatmapData] = useState([]);
  const [speakerNames, setSpeakerNames] = useState([]);
  const [tooltipContent, setTooltipContent] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jobsData = await getJobs();
        if (!jobsData || !Array.isArray(jobsData)) {
          console.error("Jobs data is not valid or not an array");
          return;
        }
        setJobs(jobsData);
        if (jobsData.length > 0 && !selectedJobId) {
          setSelectedJob(jobsData[0].id);
        }
      } catch (error) {
        console.error("Error fetching jobs:", error.message);
      }
    };

    fetchData();
  }, [getJobs, selectedJobId]);

  useEffect(() => {
    if (selectedProgram) {
      const filtered = jobs.filter((job) =>
        job.titol === selectedProgram
      );
      if (filtered.length > 0) {
        setSelectedJob(filtered[0].id);
      } else {
        setSelectedJob(null);
      }
    }
  }, [selectedProgram, jobs]);

  const getShortSpeakerName = (speakerId) => {
    return `${speakerId}`;
  };

  useEffect(() => {
    const updateHeatmapData = async () => {
      if (!selectedJob) return;

      try {
        const job = await getJobById(selectedJob);
        if (!job || !job.results || !Array.isArray(job.results)) {
          console.error("Invalid job or results data");
          return;
        }

        const validResults = job.results[0];
        if (validResults.length === 0) {
          console.error("No valid results found");
          setHeatmapData([]);
          return;
        }

        const speakerInteractionCount = validResults.reduce((acc, result) => {
          acc[result.speaker] = (acc[result.speaker] || 0) + 1;
          return acc;
        }, {});

        const speakers = Object.entries(speakerInteractionCount).map(
          ([speakerId]) => parseInt(speakerId, 10)
        );

        let topSpeakers;

        if (speakers.length > 30) {
          const filteredSpeakers = speakers.filter(
            (speakerId) => speakerInteractionCount[speakerId] >= 15
          );
          topSpeakers = filteredSpeakers.slice(0, 30);
        } else {
          topSpeakers = speakers;
        }

        if (topSpeakers.length === 0) {
          console.error("No valid speaker IDs found");
          setHeatmapData([]);
          return;
        }

        const categories = topSpeakers.map((speakerId) => {
          const speaker = validResults.find(
            (result) => result.speaker === speakerId
          );
          const speakerName =
            speaker?.speaker_name || getShortSpeakerName(speakerId);
          const speakerGender = speaker
            ? speaker.gender === "FEMALE"
              ? "F"
              : speaker.gender === "MALE"
              ? "M"
              : ""
            : "";
          return {
            id: speakerId,
            name: `${speakerName}${speakerGender}`,
            gender: speakerGender,
          };
        });

        const sortedSpeakerNames = [...categories].sort((a, b) => a.id - b.id);
        setSpeakerNames(sortedSpeakerNames);

        const interactionMatrix = {};
        topSpeakers.forEach((id1) => {
          topSpeakers.forEach((id2) => {
            interactionMatrix[`${id1}-${id2}`] = 0;
          });
        });

        for (let i = 0; i < validResults.length - 1; i++) {
          const currentSpeaker = validResults[i].speaker;
          const nextSpeaker = validResults[i + 1].speaker;
          if (
            topSpeakers.includes(currentSpeaker) &&
            topSpeakers.includes(nextSpeaker) &&
            currentSpeaker !== nextSpeaker
          ) {
            interactionMatrix[`${currentSpeaker}-${nextSpeaker}`]++;
          }
        }

        const sortedHeatmapData = sortedSpeakerNames.map((catY, indexY) => {
          return sortedSpeakerNames.map((catX, indexX) => {
            const key = `${catX.id}-${catY.id}`;
            return {
              x: indexX,
              y: indexY,
              value: interactionMatrix[key] || 0,
              tooltip: `${catX.name} a ${catY.name}: ${
                interactionMatrix[key] || 0
              } interaccions`,
            };
          });
        });

        setHeatmapData(sortedHeatmapData);
      } catch (error) {
        console.error("Error fetching or transforming data:", error.message);
      }
    };

    updateHeatmapData();
  }, [selectedJob, getJobById]);

  const getColor = (value) => {
    if (value <= 0) return "#ffffff";  // Blanco
    if (value <= 5) return "#f0f8ff";  // Azul muy claro
    if (value <= 10) return "#dbefff"; // Azul claro
    if (value <= 15) return "#b0d6ef"; // Azul intermedio claro
    if (value <= 20) return "#8cc2e3"; // Azul más intenso
    if (value <= 30) return "#6baed6"; // Azul intermedio
    if (value <= 45) return "#4292c6"; // Azul más fuerte
    if (value <= 70) return "#2171b5"; // Azul oscuro
    return "#08519c";  // Azul más oscuro
  };
  
  const showTooltip = (event, rowIndex, colIndex) => {
    const content = heatmapData[rowIndex][colIndex].tooltip;
    setTooltipContent({ rowIndex, colIndex, content });
  };

  const hideTooltip = () => {
    setTooltipContent({});
  };

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedJob(selectedValue);
  };

  return (
    <div id="chart" className="w-full max-w-screen-lg mx-auto">
      <div className="mb-4">
        <h1 className="text-xs text-black">Matriu d'interaccions</h1>
        <p className="italic text-xs text-black">
          Les matrius amb més de 30 speakers només mostraran aquells amb més de
          15 interaccions.
        </p>
      </div>
     {/*  <select
        className="text-black bg-transparent border-2 m-2"
        value={selectedJob || ""}
        onChange={handleSelectChange}
      >
        {jobs.map((job) => (
          <option key={job.id} value={job.id}>
            {job.titol}
          </option>
        ))}
      </select> */}
      <div
        className="overflow-auto p-4"
        style={{ minWidth: "300px", minHeight: "300px" }}
      >
        <div
          className="grid"
          style={{
            gridTemplateColumns: `repeat(${speakerNames.length + 1}, 1fr)`,
            gridTemplateRows: `repeat(${speakerNames.length + 1}, 1fr)`,
            gap: 0,
          }}
        >
          <div className="text-center "></div>
          {speakerNames.map((speaker, index) => (
            <div key={index} className="text-center py-1 text-black text-xs">
              {speaker.name}
            </div>
          ))}
          {heatmapData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <div className="text-center py-1 text-black text-xs">
                {speakerNames[rowIndex].name}
              </div>
              {row.map((item, colIndex) => (
                <div
                  key={`${rowIndex}-${colIndex}`}
                  className="text-white text-center relative"
                  style={{
                    backgroundColor: getColor(item.value),
                    width: "100%",
                    height: "100%",
                    zIndex: 1,
                    position: "relative",
                  }}
                  onMouseEnter={(event) =>
                    showTooltip(event, rowIndex, colIndex)
                  }
                  onMouseLeave={hideTooltip}
                >
                  {tooltipContent.rowIndex === rowIndex &&
                    tooltipContent.colIndex === colIndex && (
                      <div className="text-xs absolute top-0 left-0 transform -translate-x-1/2 -translate-y-full bg-white text-black p-4 rounded-lg shadow-lg pointer-events-none">
                        {tooltipContent.content}
                      </div>
                    )}
                </div>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HeatmapChart;
