import React, { useState, useRef } from "react";

const SpeakersList = ({ selectedJobId, speakersData }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);


  const playAudio = async (speakerWithGender) => {
    try {
      setError(null);
      if (!speakerWithGender) {
        throw new Error("Invalid speaker name");
      }
  
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
  
      const speakerIdMatch = speakerWithGender.match(/S(\d+)/);
      if (!speakerIdMatch) {
        throw new Error("Invalid speaker ID format");
      }
      const speakerIndex = speakerIdMatch[1]; 
      const speakerName = `Speaker_${speakerIndex}`;
  
      console.log(`SPEAKER NAME ${speakerName}`);
      const response = await fetch(
        `https://statmedia.ugiat.com/api/audio/${selectedJobId}/${speakerName}`,
        {
          method: "POST",
        }
      );
  
      console.log(`RESPONSE ${response}`);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
  
      setAudioUrl(url);
  
      if (audioRef.current) {
        audioRef.current.load(); 
        audioRef.current.play(); 
      }
    } catch (error) {
      console.error("Error playing audio:", error.message);
      setError(`Error: ${error.message}`);
    }
  };
  
  const validSpeakersData = speakersData.map((speaker) => ({
    ...speaker,
    totalTime: speaker.totalTime !== undefined ? speaker.totalTime : 0, 
  }));

  
  
  const topSpeakers = validSpeakersData
  .sort((a, b) => b.totalTime - a.totalTime) 
  .slice(0, 5);
  
  return (
    <div className="m-4">
      <h2>Fent clic al speaker que vulguis podràs escoltar la seva veu.</h2>
      <ul className="flex flex-row">
        {topSpeakers.map((speaker, index) => (
          <li key={index} className="m-2 text-xs">
            <button
              className="text-black bg-white p-2 rounded"
              onClick={() => playAudio(speaker.name)}
            >
              {speaker.name} {/* ({speaker.totalTime.toFixed(2)} segons) */}
            </button>
          </li>
        ))}
      </ul>
      {error && <p className="text-red-500 mt-2">{error}</p>}
      {audioUrl && (
        <div className="mt-4 flex justify-center">
          {console.log(`AUDIO REF ${audioRef}`)}
          <audio
            ref={audioRef}
            controls
            onError={(event) => {
              console.error("Audio Error:", event);
              setError(`Audio error: ${event.target.error.message}`);
            }}
          >
            <source src={audioUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>
      )}
    </div>
  );
};

export default SpeakersList;
